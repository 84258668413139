.status-indicator {
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  margin-right: 0.5em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.status-radial {
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  margin-right: 0.5em;
  position: absolute;
  top: -2px;
  left: -2px;
}

@keyframes radial-pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
