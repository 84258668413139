.about {
  text-align: center;
  flex-direction: column;
  margin-top: 3em;
  margin-bottom: 7em;
}

.about__name {
  text-transform: uppercase;
  background: linear-gradient(
    to right,
    var(--clr-secondary) 0%,
    var(--clr-primary) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about__location {
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}

.about__location .img--icon {
  font-size: 1em;
  margin-right: 5px;
}

.about__desc {
  font-size: 1.1rem;
  max-width: 600px;
}

.about__desc,
.about__contact {
  margin-top: 1.6em;
}

.about .link--icon {
  margin-left: 0.1em;
  margin-right: 0.1em;
  flex: 1;
}

.about .img--icon {
  width: 1.5em;
  height: auto;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: center;
    margin-top: 2em;
  }
}
