.lightbulb__block {
  margin: 0 auto;
  box-shadow: var(--shadow);
  border-radius: 1em;
  background-color: var(--clr-bg-alt);
  width: fit-content;
  padding: 2em 2em 1em 2em; /* Potential problems with small mobiles */
  /* transform: scale(1.1);  */
  }

.lightbulb__block-stack {
  display: block;
  text-align: center;
}

.colorful * {
  background-color: initial;
}
.colorful {
  text-align: center;
  margin-right: 2em;
}
.custom-layout .react-colorful {
  width: 300px;
  height: 280px;
}
.custom-layout .react-colorful__saturation {
  margin: 15px 0 0 0;
  border-radius: 5px;
  border-bottom: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}
.custom-layout .react-colorful__hue {
  order: -1;
}
.custom-layout .react-colorful__hue {
  height: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}
.custom-layout .react-colorful__hue-pointer {
  width: 20px;
  height: 20px;
  border-style: solid;
  border-color: #ffffff;
}
.brightness-text {
  padding-top: 1em;
}

.stack-desc {
  display: block;
  margin-bottom: 1em;
}

.controlButton {
  position: relative;
  border: none;
  background: var(--btn-bg);
  color: var(--clr-fg-alt);
  padding: 1em;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.2s;
  border-radius: 5px;
  opacity: 0.8;
  letter-spacing: 1px;
  box-shadow: var(--btn-shadow) 0px 7px 2px, #000 0px 8px 5px;
}
.controlButton:not(:disabled):hover {
  opacity: 1;
}
.controlButton:active {
  top: 4px;
  box-shadow: var(--clr-primary) 0px 3px 2px, #000 0px 3px 5px;
}

.controlButton.onButton {
  width: 80px;
  margin: 1em 1em 0 0;
}
.controlButton.onButton:active {
  box-shadow: var(--btn-shadow) 0px 3px 2px, #000 0px 3px 5px;
}
.controlButton.onButton.active {
  top: 4px;
  box-shadow: #489552 0px 3px 2px, #000 0px 3px 5px;
  cursor: default;
  background: #60c86e;
}

.controlButton.offButton {
  width: 80px;
}
.controlButton.offButton:active {
  box-shadow: var(--btn-shadow) 0px 3px 2px, #000 0px 3px 5px;
}
.controlButton.offButton.active {
  top: 4px;
  box-shadow: #b14141 0px 3px 2px, #000 0px 3px 5px;
  cursor: default;
  background: #ff5d5d;
}

.controlButton.refresh {
  width: 180px;
}
.controlButton.refresh:active {
  top: 4px;
  box-shadow: var(--btn-shadow) 0px 3px 2px, #000 0px 3px 5px;
}
.controlButton.refresh.loading {
  top: 4px;
  opacity: 0.5;
  box-shadow: var(--btn-shadow) 0px 3px 2px, #000 0px 3px 5px;
  cursor: default;
}

.controlButton.submit {
  background: var(--clr-secondary);
  box-shadow: var(--clr-primary) 0px 7px 2px, #000 0px 8px 5px;
  color: var(--clr-bg);
  width: 180px;
  margin-top: 2em;
  margin-bottom: 2.1em; /* Centering button stack vertically */
}
.controlButton.submit:active {
  top: 4px;
  box-shadow: var(--clr-primary) 0px 3px 2px, #000 0px 3px 5px;
}
.controlButton.submit.loading {
  top: 4px;
  opacity: 0.5;
  box-shadow: var(--clr-primary) 0px 3px 2px, #000 0px 3px 5px;
  cursor: default;
}

.status-container {
  text-align: center;
  font-weight: bold;
}
.status-text {
  color: var(--clr-fg-alt);
}
.status-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  border: 2px solid var(--clr-fg-alt);
  border-radius: 20%; 
}

@media (min-width: 622px) { /* Necessary for joint collapse/margin */
  .lightbulb__block-stack {
    display: inline-block;
  }
}

@media (max-width: 621px) {  /* Necessary for joint collapse/margin */
  .colorful {
    margin-right: 0;
    padding-bottom: 2em;
  }
  .stack-desc {
    display: none;
  }
  .controlButton.submit { /* Remove after centering button stack */
    margin-bottom: 1em;
  }
}

@media (max-width: 600px) {
  .custom-layout .react-colorful {
    width: 80vw;
    max-width: 300px;
    min-width: 200px;
  }
  .lightbulb__block-button-stack {
    width: 80vw;
    max-width: 300px;
    min-width: 200px;
  }
}